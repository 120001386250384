/*
  Project: Senteler
  Author: Dawid Kotlarz
 */
window.jQuery = window.$ = require('jquery');
require('lightbox2');
require('./jquery.validate.min');
require('./messages_de');
//require('./scrollreveal.min');
require('./jquery.smoove.min');
require('./functions.js');
