/**
 * Created by Dawid on 2017-03-17.
 */

function slider(){
    $('.slider .slide-prev').click(function(){
        var last = $(this).parent().data('length');
        var no= $(this).data('target');
        $(this).parent().fadeOut();
        if(no == 1){
            var slide_index = last;
            $(this).parent().parent().find('.slide-' + slide_index).fadeIn();
        }
        else{
            var slide_index = no - 1;
            $(this).parent().parent().find('.slide-' + slide_index).fadeIn();
        }
    });

    $('.slider .slide-next').click(function(){
        var last = $(this).parent().data('length');
        var no= $(this).data('target');
        $(this).parent().fadeOut();

        if(no == last){
            var slide_index = 1;
            $(this).parent().parent().find('.slide-' + slide_index).fadeIn();
        }

        else{
            var slide_index = no + 1;
            $(this).parent().parent().find('.slide-' + slide_index).fadeIn();
        }
    });
}

function offerForm(){
    $('.form-offer form').validate({
        rules:{
            email: {
                email: true,
                required: true
            },
            name:{
                required: true
            }
        },
        submitHandler: function () {
            $('.loader').show();
            $.ajax({
                type: 'post',
                url: '/wp-content/themes/senteler/send-offer.php',
                data: $('.form-offer form').serialize(),
                success: function(){
                    $('.loader').hide();
                    $('.summary').text('Ihr Formular wurde versendet.').css('color', 'white');
                    /*$( '.form-offer form' ).each(function(){
                        $('.form-offer form').reset();
                    });
                    */
                    //console.log($('.form-offer form').serialize());
                },
                error: function(request, status, error){
                    $('.summary').text('Error!!!').css('color', 'red');
                    //console.log(request.responseText);
                    //console.log(error);
                }
            });
        }
    });
}
/*
function getcv($fileId){
        var files = document.getElementById($fileId).files;
        var myArray = [];
        var file = {};

        console.log(files); // see the FileList

        // manually create a new file obj for each File in the FileList
        for(var i = 0; i < files.length; i++){

            file = {
                'lastMod'    : files[i].lastModified,
                'lastModDate': files[i].lastModifiedDate,
                'name'       : files[i].name,
                'size'       : files[i].size,
                'type'       : files[i].type,
            }

            //add the file obj to your array
            myArray.push(file)
        }

        //stringify array
        return JSON.stringify(myArray);

}
*/

function jobsForm(){
    $('.form-jobs form').validate({
        rules:{
            email: {
                email: true,
                required: true
            },
            name:{
                required: true
            }
        },
        submitHandler: function () {
            $('.loader').show();
            var form = document.querySelector('.form-jobs form');
            var formData = new FormData($('#formJobs')[0]);
            //formData.append('test', 'nowy text');
            //console.log(formData);

            $.ajax({
                type: 'POST',
                url: '/wp-content/themes/senteler/send-jobs.php',
                data:  formData,
                cache: false,
                contentType: false,
                processData: false,
                success: function(data){
                    $('.loader').hide();
                    $('.summary').text('Ihr Formular wurde versendet.').css('color', 'white');
                    /* $( '.form-jobs form' ).each(function(){
                        $('.form-jobs form').reset();
                    });
                    */
                   //console.log(data);
                },
                error: function(request, status, error){
                    //console.log(request);
                    //console.log(error);

                    $('.summary').text('Error!!!').css('color', 'red');
                }
            }).done(function(res){
                //console.log(res);
            });
        }
    });
}
$('.animate').smoove({
    offset: '15%',
    perspective: '1000px'
});

//swap only two rows
function showSublayout() {
    $('.links .link').click(function(e){
       e.preventDefault();
       var no = $(this).attr('class').split('-')[1];
       if(no == 1){
           $('.link').hide();
           $('.link-2').show();
           $('.sublayout').fadeOut();
           $('.sublayout-1').fadeIn();
       }
       else{
           $('.link').hide();
           $('.link-1').show();
           $('.sublayout').fadeOut();
           $('.sublayout-2').fadeIn();
       }
    });
}

function showCards(){
    $('.link-ferien').click(function(e) {
        e.preventDefault();
        $('.items').fadeOut();
        $('.ferien').fadeIn();
        $('.link-immobilien').fadeIn();
        $('.link-ferien').addClass('active');
    });

    $('.link-immobilien').click(function(e) {
        e.preventDefault();
        $('.items').fadeOut();
        $('.immobilien').fadeIn();
        $('.link-immobilien').fadeOut();
        $('.link-ferien').removeClass('active');
    });
}

$(document).ready(function(){
    slider();
    offerForm();
    jobsForm();
    showCards();

    $('.arrow-down').click(function(){
        $('html, body').stop().animate({
            'scrollTop': $(".text-middle").offset().top
        }, 500, 'swing', function () {
        });
    });

    var inputs = document.querySelectorAll( '.inputfile' );
    Array.prototype.forEach.call( inputs, function( input )
    {
        var label	 = input.nextElementSibling,
            labelVal = label.innerHTML;

        input.addEventListener( 'change', function( e )
        {
            var fileName = '';
            if( this.files && this.files.length > 1 )
                fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
            else
                fileName = e.target.value.split( '\\' ).pop();

            if( fileName )
                label.querySelector( 'span' ).innerHTML = fileName;
            else
                label.innerHTML = labelVal;
        });
    });
    $('.navbar_btn').click(function(){

        if($(this).hasClass('on')){
            $('header').removeClass('wide');
            $(this).removeClass('on');
            setTimeout(function(){
                document.getElementById('navbar_btn').setAttribute('aria-expanded', 'true');
            }, 500);
            //$('.c-nav-main, .footer-box').hide();
        }
        else{
            $('header').addClass('wide');
            $(this).addClass('on');
            document.getElementById('navbar_btn').setAttribute('aria-expanded', 'false');
            //$('.c-nav-main, .footer-box').show();
        }
    });
    $('header').css('background', '#FBD009');
    $('.bg-inside').css('right', '0');
    setTimeout(function(){
        $('.c-nav-main li, .footer-box').addClass('anime');
    },500);
});
